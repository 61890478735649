import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import styles from './ProjectsTablet.module.scss'
import { SectionWithSubtitle } from '../../ui/SectionWithSubtitle/SectionWithSubtitle'
import { projects } from '../../../consts/projects'
import { Paragraph } from '../../ui/Paragraph/Paragraph'
import { Tag } from '../../ui/Tag/Tag'


export const ProjectsTablet: FC = () => {
  const { t } = useTranslation()


  return (
    <SectionWithSubtitle title={t('projects.title')} subtitle={t('projects.subtitle')} id={'portfolio'}>
      <div style={{overflowX: 'auto'}}>
        <ul className={styles.projectsList}>
          {projects.map(project => (
            <li key={project.id} className={styles.project}>
              <div className={styles.projectContent}>
                <div className={styles.videoMobile}>
                  {project.video ?
                    <video autoPlay muted loop className={classNames(styles.image, styles.slideUp)} onClick={(e) => e.preventDefault()} playsInline>
                      <source src={project.video} type="video/mp4" />
                    </video> : <img
                      className={classNames(styles.image, styles.slideUp)}
                      src={project.image}
                      alt="Project image." />
                  }
                </div>
                <div className={styles.projectText}>
                  <div className={styles.projectTitle}>
                    <div className={styles.progress}>
                      <h4
                        className={styles.projectLength}>{project.id}/{projects.length} {t('projects.latest')}
                      </h4>

                      <h3 className={styles.slideUp}>{t(project.name)}</h3>
                    </div>
                    <Paragraph type={'medium regular'} className={styles.slideUp}>
                      {t(project.description)}
                    </Paragraph>
                  </div>
                  <ul className={classNames(styles.tagList, styles.slideUp)}>
                    {project.tags.map((tag: string) => (
                      <Tag tag={tag} key={tag} isHero={false} />
                    ))}
                  </ul>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </SectionWithSubtitle>
  )
}


