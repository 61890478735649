export const resources = {
  en: {
    translation: {
      buttons: {
        bookCall: 'Book a call',
        getStarted: 'Get Started',
        specialOffer: 'Special offer',
        choose: 'Choose',
        contact: 'Contact us',
      },
      services: {
        logo: 'Logo',
        uiDesign: 'UX/UI design',
        presentation: 'Presentation',
        branding: 'Branding',
        animation: '2D/3D animation',
        template: 'Template',
        kit: 'UI-kit',
        leaflet: 'Leaflet',
        ux: 'UX-design',
        stage: '3D stage',
        items: 'Branded items',
        research: 'Marketing research',
        website: 'Website',
        more: 'More...',
      },
      navigation: {
        links: {
          portfolio: 'Portfolio',
          services: 'Services',
          processes: 'How we are working',
          social: 'Social media',
          offer: 'Special offer',
        },
      },
      hero: {
        title: {
          1: 'Power up',
          2: 'your',
          3: 'startup',
          4: 'today!'
        },
        subtitle: 'Whether you\'re looking to establish a strong brand identity or seeking to impress potential investors, our team is dedicated to helping you make a lasting impact in the market.',
      },
      gallery: {
        title: 'Ready to stand out?',
        subtitle: 'Let us guide you through the process of creating a distinctive brand that stands out and resonates with your target audience.',
        experience: 'Over 7 thrilling years of experience!',
      },
      offer: {
        subtitle: 'special offer',
        title: 'The package is twice as profitable, check out our offers',
        included: 'What\'s included:',
        packages: {
          standard: {
            title: 'Standard',
            subtitle: 'The most necessary things at the start of a project',
            price: '$930',
            deadline: '2 weeks all work',
            services: {
              logoConcept: '1 logo concept (2 edits)',
              logobook: 'Logobook with rules, colors and fonts',
              landing: 'Landing page (5 screens)',
              presentation: 'Presentation (5 slides)',
            },
          },
          pro: {
            title: 'Pro',
            subtitle: 'More advanced package with flexible terms',
            price: '$1 600',
            deadline: '4 weeks all work',
            services: {
              logoConcept: '2 logo concepts (2 edits)',
              logobook: 'Logobook with rules, colors and fonts',
              landing: 'Landing page (8 screens)',
              presentation: 'Presentation (10 slides)',
            },
          },
        },
      },
      bookCall: {
        title: 'Book a call',
        subtitle: 'Learn more about how our agency works and how it can help you',
      },
      process: {
        subtitle: 'Process',
        title: 'We work fast, so fasten your seatbelts!',
        week: 'week',
        logo: 'Logo creation',
        landing: 'Landing page development',
        presentation: 'Creating a presentation',
        logobook: 'Logobook with rules, colors and fonts',
        testing: 'Testing and improvements',
      },
      servicesSection: {
        title: 'Here\'s what else we do ',
        subtitle: 'If your project needs not only branding, development, but also, for example, digital marketing or jurisprudence, we will attract \n' +
          'the best experts for your project with \n' +
          'a focus on anything.',
        branding: {
          title: 'Branding',
          elaboration: 'Positioning elaboration ',
          logo: 'Logo design',
          identity: 'Brand Identity',
          brandbook: 'Brandbook & guidelines',
          video: 'Video for business',
        },
        illustration: {
          title: 'Illustration',
          flatArt: 'Flat and line art (2D)',
          illustration3D: '3D illustrations',
          collage: 'Collage',
          characters: 'Characters',
          animated: 'Animated illustration',
        },
        web: {
          title: 'Web Design',
          search: 'Web style search',
          concept: 'Design Concept',
          kit: 'UI-kit',
        },
        event: {
          title: 'Event Packaging',
          stage: 'Event stage design',
          handouts: 'Design of handouts',
          promo: 'Promo clip',
          production: 'Production',
        },
        motion: {
          title: 'Motion',
          graphics: 'Motion graphics',
          logo: 'Logo animations',
          web: 'Web animation',
          interaction: 'Interaction design',
          identity: 'Motion identity',
        },
        graphic: {
          title: 'Graphic design',
          presentation: 'Presentation',
          cards: 'Business cards / Packages / Badges',
          booklets: 'Booklets / Leaflets / Catalogues',
          billboard: 'Billboard / Roll Up / Banners',
          documentation: 'Documentation',
        },
        development: {
          title: 'Development',
          custom: 'Custom development',
          webflow: 'Webflow',
          tilda: 'Tilda',
          wordpress: 'Wordpress',
          bitrix: 'Bitrix and Bitrix 24',
          landing: 'Create landing page',
          improvement: 'Improve current site',
          support: 'Project support',
        },
      },
      projects: {
        subtitle: 'Projects',
        title: 'We have compiled projects with different types of services',
        latest: 'latest projects',
        jupiter: {
          title: 'Startup packaging for Jupiter company',
          subtitle: 'Comprehensive work on the brand book and website\n' +
            'for Jupiter, the company is focused on providing investment opportunities in alternative and environmental assets through a regulated securities exchange in the United States.',
        },
        INTI: {
          title: 'We helped INTI with event design in Abu Dhabi',
          subtitle: 'Developed the INTI.tech day sub-brand, created a stunning 3D scene for their event, and built a web portal that fully meets the company\'s needs. Additionally, we have produced over five captivating video clips, including one that captivates viewers with its mesmerizing 3D graphics design.',
        },
        CHARME: {
          title: 'How we helped enter new markets',
          subtitle: 'The company CHARME Perfume from Vietnam approached us for conducting a marketing research and successful entry into the Russian market. We conducted competitor analysis, studied consumer preferences, and trends in the perfume industry. We created over 60 packaging designs for perfumes.',
        },
        Rumped: {
          title: 'Presentation for startup Rumped in New York',
          subtitle: 'We took it upon ourselves to make a presentation to investors, showing the exceptional uniqueness and potential of the Rumped startup.',
        },
        Xiaomi: {
          title: 'E-commerce website for Xiaomi retailer',
          subtitle: 'The company CHARME Perfume from Vietnam approached us for conducting a marketing research and successful entry into the Russian market. We conducted competitor analysis, studied consumer preferences, and trends in the perfume industry. We created over 60 packaging designs for perfumes.',
        },
        Aerogaz: {
          title: 'About the company\'s products in the video',
          subtitle: 'Improving the logo and creating a video about the company’s activities and the products it develops in the oil and gas industry',
        },
      },
      quotes: {
        jupiter: {
          text: 'We were impressed with their ability to develop a highly customized and unique brand concept in a short timeframe.',
          name: 'David',
          company: 'Jupiter',
          country: 'USA',
        },
      },
      cta: {
        title: 'Let’s collaborate',
      },
      footer: {
        presentation: 'Presentation of the company',
        copyright: 'LAMA.agency. All rights reserved.',
        location: 'Cyprus',
        phone: '+357 97 935386',
      },
    },
  },
  ru: {
    translation: {
      buttons: {
        bookCall: 'Связаться',
        getStarted: 'Оставить заявку',
        specialOffer: 'Наши предложения',
        choose: 'Выбрать',
        contact: 'Связаться',
      },
      services: {
        logo: 'Logo',
        uiDesign: 'UX/UI design',
        presentation: 'Презентация',
        branding: 'Брендинг',
        animation: '2D/3D animation',
        template: 'Шаблоны',
        kit: 'UI-kit',
        leaflet: 'Листовка',
        ux: 'UX-дизайн',
        stage: '3D stage',
        items: 'Branded items',
        research: 'Marketing research',
        website: 'Website',
        more: 'еще...',
      },
      navigation: {
        links: {
          portfolio: 'Портфолио',
          services: 'Услуги',
          processes: 'Как мы работаем',
          social: 'Социальные сети',
          offer: 'Наше предложение',
        },
      },
      hero: {
        title: {
          1: 'Разрабатываем',
          2: 'цифровые',
          3: 'продукты',
          4: ''
        },
        subtitle: 'Мы создаем цифровых продукты, где каждая деталь несет в себе смысл и важность, а каждая идея имеет свою цель и направление.',
      },
      gallery: {
        title: 'Обсудим ваш проект?',
        subtitle: 'Каждый проект уникален, каждый набор услуг будет подобран под вашу конкретную ситуацию и задачи',
        experience: 'Более 7 лет опыта',
      },
      offer: {
        subtitle: 'специальное предложение',
        title: 'Планируете ребрендинг или открытие нового бизнеса? Тогда это для вас!',
        included: 'Что включено:',
        packages: {
          standard: {
            title: 'Standard',
            subtitle: 'Самое необходимое для  запуска проекта/бизнеса',
            price: '95 000 руб.',
            deadline: 'Срок: 2 недели',
            services: {
              logoConcept: '1 концепция логотипа (2 внесения изменений)',
              logobook: 'Logobook: правила, цвета, шрифты',
              landing: 'Лендинг (5 экранов)',
              presentation: 'Презентация (5 слайдов)',
            },
          },
          pro: {
            title: 'Pro',
            subtitle: 'More advanced package with flexible terms',
            price: '160 000 руб.',
            deadline: 'Срок: 4 недели',
            services: {
              logoConcept: '2 концепции логотипа (2 внесения изменений)',
              logobook: 'Logobook: правила, цвета, шрифты',
              landing: 'Лендинг (8 экранов)',
              presentation: 'Презентация (10 слайдов)',
            },
          },
        },
      },
      bookCall: {
        title: 'Свяжитесь с нами',
        subtitle: 'Узнайте больше о том, как работает наше агентство и чем мы можем вам помочь',
      },
      process: {
        subtitle: 'Этапы работы',
        title: 'Скорость, точность, результаты! Как мы это делаем',
        week: 'неделя',
        logo: 'Разработка логотипа',
        landing: 'Разработка лендинга',
        presentation: 'Создание презентации',
        logobook: 'Logobook с правилами, цветами и шрифтами',
        testing: 'з',
      },
      servicesSection: {
        title: 'С чем мы можем вам помочь?',
        subtitle: 'Если вашему проекту нужен не только брендинг, разработка, но и, например, цифровой маркетинг или юриспруденция, мы привлечем лучших специалистов для вашего проекта с фокусом на что угодно',
        branding: {
          title: 'Брендинг',
          elaboration: 'Разработка позиционирования',
          logo: 'Дизайн логотипа',
          identity: 'Фирменный стиль',
          brandbook: 'Брендбук и гайдлайн',
          video: 'Видео для бизнеса',
        },
        illustration: {
          title: 'Иллюстрации',
          flatArt: 'Flat дизайн (2D)',
          illustration3D: '3D иллюстрации',
          collage: 'Инфографика',
          characters: 'Персонажи',
          animated: 'Анимированные иллюстрации',
        },
        web: {
          title: 'Web-дизайн',
          search: 'Поиск референсов',
          concept: 'Дизайн концепции',
          kit: 'UI-kit',
        },
        event: {
          title: 'Упаковка мероприятий',
          stage: 'Оформление мероприятий',
          handouts: 'Дизайн раздаточных материалов',
          promo: 'Промо-ролик',
          production: 'Продакшн',
        },
        motion: {
          title: 'Motion',
          graphics: 'Motion графика',
          logo: 'Анимация логотипа ',
          web: 'Web анимации',
          interaction: 'Interaction design',
          identity: 'Motion identity',
        },
        graphic: {
          title: 'Графический дизайн',
          presentation: 'Презентации',
          cards: 'Визитки / Упаковка /Бейджи',
          booklets: 'Буклеты / Листовки / Каталоги',
          billboard: 'Billboard / Roll Up / Баннеры',
          documentation: 'Шаблон документов в Word',
        },
        development: {
          title: 'Разработка',
          custom: 'Кастомизированная разработка',
          webflow: 'Webflow',
          tilda: 'Tilda',
          wordpress: 'Wordpress',
          bitrix: 'Bitrix и Bitrix 24',
          landing: 'Создание лендинга',
          improvement: 'Улучшение сайта',
          support: 'Поддердка и сопровождение',
        },
      },
      projects: {
        subtitle: 'Проекты',
        title: 'Мы собрали проекты с разным видом услуг',
        latest: 'Последних проектов',
        jupiter: {
          title: 'Полный пакет для стартапа из США',
          subtitle: 'Комплексная работа над брендбуком и сайтом для Jupiter, компания занимается предоставлением инвестиционных возможностей в альтернативные и экологические активы через регулируемую биржу ценных бумаг на территории США.',
        },
        INTI: {
          title: 'We helped INTI with event design in Abu Dhabi',
          subtitle: 'Developed the INTI.tech day sub-brand, created a stunning 3D scene for their event, and built a web portal that fully meets the company\'s needs. Additionally, we have produced over five captivating video clips, including one that captivates viewers with its mesmerizing 3D graphics design.',
        },
        CHARME: {
          title: 'How we helped enter new markets',
          subtitle: 'The company CHARME Perfume from Vietnam approached us for conducting a marketing research and successful entry into the Russian market. We conducted competitor analysis, studied consumer preferences, and trends in the perfume industry. We created over 60 packaging designs for perfumes.',
        },
        Rumped: {
          title: 'Presentation for startup Rumped in New York',
          subtitle: 'We took it upon ourselves to make a presentation to investors, showing the exceptional uniqueness and potential of the Rumped startup.',
        },
        Xiaomi: {
          title: 'E-commerce website for Xiaomi retailer',
          subtitle: 'The company CHARME Perfume from Vietnam approached us for conducting a marketing research and successful entry into the Russian market. We conducted competitor analysis, studied consumer preferences, and trends in the perfume industry. We created over 60 packaging designs for perfumes.',
        },
        Aerogaz: {
          title: 'About the company\'s products in the video',
          subtitle: 'Improving the logo and creating a video about the company’s activities and the products it develops in the oil and gas industry',
        },
      },
      quotes: {
        jupiter: {
          text: 'We were impressed with their ability to develop a highly customized and unique brand concept in a short timeframe.',
          name: 'David',
          company: 'Jupiter',
          country: 'USA',
        },
      },
      cta: {
        title: 'Начнем работать?',
      },
      footer: {
        presentation: 'Презентация компании',
        copyright: 'LAMA.agency. All rights reserved.',
        location: 'Россия',
        phone: '+7 981 969 72 17 (Telegram)',
        email: 'hello@lama.global',
      },
    },
  },
}