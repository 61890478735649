import  { FC } from 'react'
import { Helmet } from 'react-helmet'
import i18n from 'i18next'

export const HelmetComponent: FC = () => {

  const PAGE_TITLE = 'LAMA (Look At Me Agency)'
  const PAGE_DESCRIPTION = i18n.language === 'ru' ? 'Цифровое агенство полного цикла. Аналитика, UX/UI, графический и motion дизайн, программирование, интеграции, поддержка и развитие цифровых продуктов.' :'Full service digital agency. Analytics, UX/UI, graphic and motion design, programming, integration, support and development of digital products.'
  const PAGE_URL = 'https://example.com/your-page-url'

  return (
    <Helmet>
      <title>{PAGE_TITLE}</title>
      <meta name="description" content={PAGE_DESCRIPTION} />
      <link rel="canonical" href={PAGE_URL} />

      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charSet="UTF-8" />

      <meta property="og:title" content={PAGE_TITLE} />
      <meta property="og:description" content={PAGE_DESCRIPTION} />
      <meta property="og:url" content={PAGE_URL} />
      <meta property="og:type" content="website" />

      <meta name="twitter:title" content={PAGE_TITLE} />
      <meta name="twitter:description" content={PAGE_DESCRIPTION} />

      <script type="application/ld+json">
        {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Look At Me Agency",
        "url": "http://example.com",
        "logo": "http://example.com/logo.png"
      }
    `}
      </script>
    </Helmet>
  )
}