import styles from './Footer.module.scss'
import { FC } from 'react'
import { Paragraph } from '../ui/Paragraph/Paragraph'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import FacebookIcon from '../../assets/icons/socials/facebook.svg?react'
import InstagramIcon from '../../assets/icons/socials/instagram.svg?react'
import LinkedInIcon from '../../assets/icons/socials/linkedin.svg?react'
import { SocialLink } from '../ui/SocialLink/SocialLink'
import { useBreakpoints } from '../../hooks/useBreakpoints'

export const Footer: FC = () => {
  function getCurrentYear(): number {
    const currentDate = new Date()
    return currentDate.getFullYear()
  }

  const {IS_MOBILE} = useBreakpoints()

  const { t } = useTranslation()
  return (
    <footer className={styles.footer}>
      <div className={styles.links}>
        <div className={styles.list}>
          <div className={styles.socials}>
            <SocialLink icon={FacebookIcon} path={'https://www.facebook.com/profile.php?id=100094235588568'}/>
            <SocialLink icon={InstagramIcon} path={'https://www.instagram.com/lama.global/'}/>
            <SocialLink icon={LinkedInIcon} path={'https://www.linkedin.com/in/lama-global-013515301/'}/>
          </div>
          <a className={classNames('linkHover', styles.link)}>
            {t('footer.presentation')}
          </a>
        </div>
        {!IS_MOBILE && <Paragraph type={'small medium'}>
          &copy; {getCurrentYear()} {t('footer.copyright')}
        </Paragraph>}
      </div>
      <div className={styles.address}>
        <address>
          <Paragraph type={'small medium grey'}>
            {t('footer.location')}
          </Paragraph>
        </address>
        <a href={'tel:+35797935386'} className={classNames('linkHover', styles.link)}>
          <Paragraph type={'small medium'}>
            {t('footer.phone')}
          </Paragraph>
        </a>
        <a href={'mailto:hello@lama.global'} className={classNames('linkHover', styles.link)}>
          <Paragraph type={'small medium'}>
            hello@lama.global
          </Paragraph>
        </a>
      </div>
      {IS_MOBILE && <Paragraph type={'small medium'}>
        &copy; {getCurrentYear()} {t('footer.copyright')}
      </Paragraph>}
    </footer>
  )
}


