export interface OfferProps {
  name: string
  description: string
  price: string
  deadline: string
  services: string[]
  color: string
  buttonColor: string
}
export const offers = [
  {
    name: 'offer.packages.standard.title',
    description: 'offer.packages.standard.subtitle',
    price: 'offer.packages.standard.price',
    color: 'var(--purple-02)',
    buttonColor: 'var(--purple-04)',
    deadline: 'offer.packages.standard.deadline',
    services: [
      'offer.packages.standard.services.logoConcept',
      'offer.packages.standard.services.logobook',
      'offer.packages.standard.services.landing',
      'offer.packages.standard.services.presentation',
    ],
  },
  {
    name: 'offer.packages.pro.title',
    description: 'offer.packages.pro.subtitle',
    price: 'offer.packages.pro.price',
    color: 'var(--blue-01)',
    buttonColor: 'var(--blue-03)',
    deadline: 'offer.packages.pro.deadline',
    services: [
      'offer.packages.pro.services.logoConcept',
      'offer.packages.pro.services.logobook',
      'offer.packages.pro.services.landing',
      'offer.packages.pro.services.presentation',
    ],
  }
]