import styles from './ContactUsModal.module.scss'
import { FC, useContext, useEffect, useRef, useState } from 'react'
import { Paragraph } from '../Paragraph/Paragraph'
import { AccordionComponent } from '../Accordion/Accordion'
import { offers } from '../../../consts/offers'
import { useModal } from '../../../hooks/useModal'
import { ModalContext } from '../../../app/provider/ModalContext'
import { Button } from '../Button/Button'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SelectedItemProvider } from '../../../contexts/SelectedItemContext'
import axios from 'axios'
import { useBreakpoints } from '../../../hooks/useBreakpoints'
import { SuccessContent } from './SuccessContent/SuccessContent'

const botToken = import.meta.env.VITE_TELEGRAM_BOT_TOKEN
const chatId = import.meta.env.VITE_TELEGRAM_CHAT_ID

interface FormData {
  name: string;
  email: string;
  package: string;
}

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  package: yup.string().required('Choose package'),
})

export const ContactUsModal: FC = () => {
  const [isClosing, setIsClosing] = useState<boolean>(false)
  const { closeModal } = useModal()
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  const overlayRef = useRef<HTMLDivElement>(null)
  const {BUTTON_MEDIUM} = useBreakpoints()
  const { defaultValue } = useContext(ModalContext) ?? {}
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  })

  const handleSelectedItemChange = (itemName: string) => {
    let packageValue = ''
    if (itemName === 'offer.packages.standard.title') {
      packageValue = 'Standard'
    } else if (itemName === 'offer.packages.pro.title') {
      packageValue = 'Pro'
    }
    setValue('package', packageValue)
  }


  useEffect(() => {
    if (defaultValue && (defaultValue === 'offer.packages.standard.title' || defaultValue === 'offer.packages.pro.title')) {
      let packageValue = ''
      if (defaultValue === 'offer.packages.standard.title') {
        packageValue = 'Standard'
      } else if (defaultValue === 'offer.packages.pro.title') {
        packageValue = 'Pro'
      }
      setValue('package', packageValue)
    }
  }, [defaultValue, setValue])

  const onSubmit = async (data: FormData) => {

    const text = `Name: ${data.name}\nEmail: ${data.email}\nPackage: ${data.package}`

    try {
      await axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
        chat_id: chatId,
        text: text,
      })
      setIsSuccess(true)
    } catch (error) {
      console.error('Error sending message:', error)
      setIsSuccess(false)
    }
  }


  useEffect(() => {
    if (isClosing) {
      const timer = setTimeout(() => {
        closeModal()
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [isClosing, closeModal])

  const handleModalClose = () => {
    setIsClosing(true)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setIsClosing(true)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [modalRef])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsClosing(true)
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <SelectedItemProvider>
      <div ref={overlayRef} className={`${styles.overlay} ${isClosing ? styles.fadeOut : ''}`}>
        <div ref={modalRef} className={`${styles.modal} ${isClosing ? styles.scaleDown : ''}`}>
          <button className={styles.closeButton} onClick={handleModalClose}>
            <Paragraph type={'caption-xl bold'}>close</Paragraph>
          </button>
          {isSuccess ? <SuccessContent/> : <div className={styles.content}>
            <form noValidate className={styles.form} onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.formContent}>
                <h3>Contact form</h3>
                <div className={styles.inputs}>
                  <div className={styles.errorContainer}>
                    <input
                      type={'text'}
                      placeholder={'Your name'}
                      className={styles.input}
                      {...register('name')}
                    />
                    {errors.name &&
                      <Paragraph
                        type={'caption-s regular red'}
                        className={styles.error}>
                        {errors.name.message}
                      </Paragraph>
                    }
                  </div>
                  <div className={styles.errorContainer}>
                    <input
                      type={'email'}
                      placeholder={'Enter your email'}
                      className={styles.input}
                      {...register('email')}
                    />
                    {errors.email &&
                      <Paragraph
                        type={'caption-s regular red'}
                        className={styles.error}
                      >
                        {errors.email.message}
                      </Paragraph>
                    }
                  </div>
                </div>
              </div>
              <div className={styles.errorContainer}>
                {errors.package &&
                  <Paragraph
                    type={'caption-s regular red'}>
                    {errors.package.message}
                  </Paragraph>
                }
                <Button size={BUTTON_MEDIUM ? 'm' : 'l'} type={'submit'} buttonText={'Send'} />
              </div>
            </form>
            <div className={styles.accordionContainer}>
              {defaultValue === '' &&
                <Paragraph type={'medium regular'} className={styles.paragraph}>
                  Take advantage of a <span>special offer</span>, it’s always more profitable and faster
                </Paragraph>
              }
              <AccordionComponent
                items={offers}
                defaultValue={defaultValue}
                onSelectedItemChange={handleSelectedItemChange} />
            </div>
          </div>
          }
        </div>
      </div>
    </SelectedItemProvider>
  )
}