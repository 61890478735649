import styles from './Table.module.scss'
import { FC } from 'react'
import classNames from 'classnames'
import { Paragraph } from '../Paragraph/Paragraph'
import { useTranslation } from 'react-i18next'

export const Table: FC<{ isStandard: boolean }> = ({ isStandard }) => {
  const { t } = useTranslation()
  return (
    <table
      className={styles.parent}
      style={{backgroundColor: isStandard ? '#F5F4FF' : '#E1ECF1'}}>
      <thead>
      <tr>
        <th className={classNames(styles.border, styles.column)}>
          <Paragraph type={'medium bold grey'}>
            1 {t('process.week')}
          </Paragraph>
        </th>
        <th className={classNames(styles.border, styles.column)}>
          <Paragraph type={'medium bold grey'}>
            2 {t('process.week')}
          </Paragraph>
        </th>
        {!isStandard &&
          <>
            <th className={classNames(styles.border, styles.column)}>
              <Paragraph type={'medium bold grey'}>
                3 {t('process.week')}
              </Paragraph>
            </th>
            <th className={styles.column}>
              <Paragraph type={'medium bold grey'}>
                4 {t('process.week')}
              </Paragraph>
            </th>
          </>
        }
      </tr>
      </thead>
      <tbody>
      {!isStandard ?
        <tr className={styles.empty}>
          <td className={styles.border}></td>
          <td className={styles.border}></td>
          <td className={styles.border}></td>
          <td></td>
        </tr> :
        <tr className={styles.empty}>
          <td className={styles.border}></td>
          <td></td>
        </tr>
      }

      {!isStandard ?
        <tr>
          <td className={styles.border}>
            <Paragraph
              type={'small regular'}
              className={classNames(styles.active)}
            >
              {t('process.logo')}
            </Paragraph>
          </td>
          <td className={styles.border}></td>
          <td className={styles.border}></td>
          <td></td>
        </tr> :
        <tr>
          <td className={styles.border}>
            <Paragraph type={'small regular'} className={classNames(styles.active, styles.standardActive)}>
              {t('process.logo')}
            </Paragraph>
          </td>
          <td></td>
        </tr>
      }
      {!isStandard ?
        <tr className={styles.empty}>
          <td className={styles.border}></td>
          <td className={styles.border}></td>
          <td className={styles.border}></td>
          <td></td>
        </tr> :
        <tr className={styles.empty}>
          <td className={styles.border}></td>
          <td></td>
        </tr>
      }
      {!isStandard ?
        <tr>
          <td className={styles.border} colSpan={3}>
            <div className={styles.pContainer}>
              <Paragraph type={'small regular'} className={classNames(styles.active, styles.logo)}>
                {t('process.landing')}
              </Paragraph>
            </div>
          </td>
          <td></td>
        </tr> :
        <tr>
          <td className={styles.border} colSpan={2}>
            <div className={styles.pContainer}>
              <Paragraph type={'small regular'} className={classNames(styles.active, styles.devPres, isStandard && styles.standardActive)}>
                {t('process.landing')}
              </Paragraph>
            </div>
          </td>
        </tr>
      }
      {!isStandard ?
        <tr className={styles.empty}>
          <td className={styles.border}></td>
          <td className={styles.border}></td>
          <td className={styles.border}></td>
          <td></td>
        </tr> :
        <tr className={styles.empty}>
          <td className={styles.border}></td>
          <td></td>
        </tr>
      }
      {!isStandard ?
        <tr>
          <td className={styles.border}></td>
          <td className={styles.border} colSpan={2}>
            <Paragraph type={'small regular'} className={classNames(styles.active, styles.presentation)}>
              {t('process.presentation')}
            </Paragraph>
          </td>
          <td></td>
        </tr> :
        <tr>
          <td className={styles.border} colSpan={2}>
            <div className={styles.pContainer}>
              <Paragraph type={'small regular'} className={classNames(styles.active, styles.devPres, isStandard && styles.standardActive)}>
                {t('process.presentation')}
              </Paragraph>
            </div>
          </td>
        </tr>
      }

      {!isStandard ?
        <tr className={styles.empty}>
          <td className={styles.border}></td>
          <td className={styles.border}></td>
          <td className={styles.border}></td>
          <td></td>
        </tr> :
        <tr className={styles.empty}>
          <td className={styles.border}></td>
          <td></td>
        </tr>
      }

      {!isStandard ?
        <tr>
          <td className={styles.border} colSpan={2}>
            <div className={styles.pContainer}>
              <Paragraph type={'small regular'} className={classNames(styles.active, styles.logobook)}>
                {t('process.logobook')}
              </Paragraph>
            </div>
          </td>
          <td className={styles.border}></td>
          <td></td>
        </tr> :
        <tr>
          <td className={styles.border}></td>
          <td>
            <Paragraph type={'small regular'} className={classNames(styles.active, isStandard && styles.standardActive)}>
              {t('process.logobook')}
            </Paragraph>
          </td>
        </tr>
      }

      {!isStandard ?
        <tr className={styles.empty}>
          <td className={styles.border}></td>
          <td className={styles.border}></td>
          <td className={styles.border}></td>
          <td></td>
        </tr> :
        <tr className={styles.empty}>
          <td className={styles.border}></td>
          <td></td>
        </tr>
      }

      {!isStandard && <>
        <tr>
          <td className={styles.border}></td>
          <td className={styles.border}></td>
          <td className={styles.border}></td>
          <td>
            <Paragraph type={'small regular'} className={styles.active}>
              {t('process.testing')}
            </Paragraph>
          </td>
        </tr>
        <tr className={styles.empty}>
          <td className={styles.border}></td>
          <td className={styles.border}></td>
          <td className={styles.border}></td>
          <td></td>
        </tr>
      </>}
      </tbody>
    </table>
  )
}