import  { FC, useState, ReactNode } from 'react'
import { ModalContext } from './ModalContext'

export const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [defaultValue, setDefaultValue] = useState<string | undefined>('')

  const openModal = (defaultValue?: string) => {
    setIsModalOpen(true)
    setDefaultValue(defaultValue)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setDefaultValue('')
  }

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal, defaultValue }}>
      {children}
    </ModalContext.Provider>
  )
}


