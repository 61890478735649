import { useCallback } from 'react'

export const useScrollToSection = () => {
  return useCallback((id: string) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])
}
