import styles from './SpecialOffer.module.scss'
import { FC } from 'react'
import { SectionWithSubtitle } from '../ui/SectionWithSubtitle/SectionWithSubtitle'
import { offers } from '../../consts/offers'
import { BookCall } from '../ui/BookCall/BookCall'
import { Offer } from '../ui/Offer/Offer'
import { useTranslation } from 'react-i18next'


export const SpecialOffer: FC = () => {
  const { t } = useTranslation()
  return (
    <SectionWithSubtitle title={t('offer.title')} subtitle={t('offer.subtitle')} id={'offer'}>
      <div className={styles.specialOfferContent}>
        <ul className={styles.specialOfferPackageContainer}>
          {offers.map(offer => (
            <Offer key={offer.name} offer={offer}/>
          ))}
        </ul>
        <BookCall/>
      </div>
    </SectionWithSubtitle>

  )
}


