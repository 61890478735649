import styles from './LinkComponent.module.scss'
import { FC } from 'react'
import classNames from 'classnames'
import { useTextStyles } from '../../../hooks/useTextStyles'
import { useScrollToSection } from '../../../hooks/useScrollToSection'

interface Props {
  path: string
  linkText: string
  linkStyles?: string
  type: string
  isHover?: boolean
}
export const LinkComponent: FC<Props>= ({type, path, linkText, linkStyles, isHover = true}) => {
  const {fontSizeType, fontWeightType} = useTextStyles(type)
  const scrollToSection = useScrollToSection()

  const handleClick = () => {
    scrollToSection(path)
  }
  return (
    <a
      onClick={handleClick}
      className={classNames(styles.link, isHover && 'linkHover', linkStyles, fontSizeType, fontWeightType)}
    >
      {linkText}
    </a>
  )
}