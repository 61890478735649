import styles from './Quote.module.scss'
import { FC } from 'react'
import QuoteIcon from '../../assets/icons/quote.svg?react'
import { Paragraph } from '../ui/Paragraph/Paragraph'
import { useTranslation } from 'react-i18next'

export const Quote: FC = () => {
  const { t } = useTranslation()
  return (
    <section className={styles.quoteSection}>
      <QuoteIcon className={styles.quoteIcon}/>
      <blockquote className={styles.quoteContainer} >
        <Paragraph type={'x-large regular'} >{t('quotes.jupiter.text')}</Paragraph>
        <footer>
          <Paragraph type={'medium regular'}>
            {t('quotes.jupiter.name')}, <cite>{t('quotes.jupiter.company')}</cite>, {t('quotes.jupiter.country')}
          </Paragraph>
        </footer>
      </blockquote>
    </section>
  )
}