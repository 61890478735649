import styles from './Hero.module.scss'
import { FC } from 'react'
import { Paragraph } from '../ui/Paragraph/Paragraph'
import { Tag } from '../ui/Tag/Tag'
import { additionalServicesRu, servicesHero } from '../../consts/servicesHero'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'

export const Hero: FC = () => {
  const { t } = useTranslation()

  const servicesToDisplay = i18n.language === 'ru' ? [ ...additionalServicesRu, ...servicesHero.slice(1)] : servicesHero
  return (
    <section className={styles.hero}>
      <div className={styles.heroText}>
        <div className={styles.heading}>
          <div>
            <h1 className={styles.slideUp}>{t('hero.title.1')}&nbsp;</h1>
          </div>
          <div>
            <h1 className={styles.slideUp}>{t('hero.title.2')}&nbsp;</h1>
          </div>
          <div>
            <h1 className={styles.slideUp}>{t('hero.title.3')}&nbsp;</h1>
          </div>
          <div>
            <h1 className={styles.slideUp}>{t('hero.title.4')}</h1>
          </div>
        </div>
        <Paragraph type={'large regular'} className={styles.heroSubtitle}>
          {t('hero.subtitle')}</Paragraph>
      </div>
      <ul className={styles.herServices}>
        {servicesToDisplay.map(service => (
          <Tag tag={service} key={service} />
        ))}
      </ul>
    </section>
  )
}


