import React, { useEffect, useRef } from 'react'
import styles from './VideoOnHover.module.scss'
import classNames from 'classnames'

interface VideoOnHoverProps {
  // imageSrc: string
  videoSrc: string
  containerStyles: string
}

export const VideoOnHover: React.FC<VideoOnHoverProps> = ({ videoSrc, containerStyles }) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const video = videoRef.current
    if (video) {
      video.addEventListener('canplay', () => {
        video.play()
      })
    }
  }, [])

  return (
    <div className={classNames(styles.videoContainer, containerStyles)}>
      <video
        ref={videoRef}
        className={styles.video}
        loop
        muted
        autoPlay
        playsInline
        onClick={(e) => e.preventDefault()}>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  )
}

