export const services = [
  {
    number: '01',
    name: 'servicesSection.branding.title',
    work: [
      'servicesSection.branding.elaboration',
      'servicesSection.branding.logo',
      'servicesSection.branding.identity',
      'servicesSection.branding.brandbook',
      'servicesSection.branding.video'
    ]
  },
  {
    number: '02',
    name: 'servicesSection.illustration.title',
    work: [
      'servicesSection.illustration.flatArt',
      'servicesSection.illustration.illustration3D',
      'servicesSection.illustration.collage',
      'servicesSection.illustration.characters',
      'servicesSection.illustration.animated'
    ]
  },
  {
    number: '03',
    name: 'servicesSection.web.title',
    work: [
      'servicesSection.web.search',
      'servicesSection.web.concept',
      'servicesSection.web.kit',
    ]
  },
  {
    number: '04',
    name: 'servicesSection.event.title',
    work: [
      'servicesSection.event.stage',
      'servicesSection.event.handouts',
      'servicesSection.event.promo',
      'servicesSection.event.production',
    ]
  },
  {
    number: '05',
    name: 'servicesSection.motion.title',
    work: [
      'servicesSection.motion.graphics',
      'servicesSection.motion.logo',
      'servicesSection.motion.web',
      'servicesSection.motion.interaction',
      'servicesSection.motion.identity'
    ]
  },
  {
    number: '06',
    name: 'servicesSection.graphic.title',
    work: [
      'servicesSection.graphic.presentation',
      'servicesSection.graphic.cards',
      'servicesSection.graphic.booklets',
      'servicesSection.graphic.billboard',
      'servicesSection.graphic.documentation'
    ]
  },
  {
    number: '07',
    name: 'servicesSection.development.title',
    work: [
      'servicesSection.development.custom',
      'servicesSection.development.webflow',
      'servicesSection.development.tilda',
      'servicesSection.development.wordpress',
      'servicesSection.development.bitrix',
      'servicesSection.development.landing',
      'servicesSection.development.improvement',
      'servicesSection.development.support'
    ]
  },




]