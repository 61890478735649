export const navigation = [
  {
    linkText: 'navigation.links.portfolio',
    path: 'portfolio'
  },
  {
    linkText: 'navigation.links.services',
    path: 'services'
  },
  {
    linkText: 'navigation.links.processes',
    path: 'process'
  }
]