import { CSSProperties, FC, useEffect } from 'react'
import { OfferProps } from '../../../consts/offers'
import styles from './Accordion.module.scss'
import { Paragraph } from '../Paragraph/Paragraph'
import BulletIcon from '../../../assets/bullet.svg?react'
import { Button } from '../Button/Button'
import * as Accordion from '@radix-ui/react-accordion'
import ArrowDownIcon from '../../../assets/icons/arrowDown.svg?react'
import { useTranslation } from 'react-i18next'
import { useSelectedItem } from '../../../contexts/SelectedItemContext'

interface Props {
  items: OfferProps[]
  defaultValue?: string
  onSelectedItemChange: (itemName: string) => void;
}

export const AccordionComponent: FC<Props> = ({ items, defaultValue, onSelectedItemChange }) => {
  const { t } = useTranslation()
  const { selectedItem, setSelectedItem } = useSelectedItem()

  useEffect(() => {
    if(defaultValue) {
      setSelectedItem(defaultValue)
    } else {
      setSelectedItem('')
    }
  }, [defaultValue, setSelectedItem, onSelectedItemChange])


  const accordionValue = () => {
    if (defaultValue === '') {
      return 'offer.packages.pro.title'
    } else {
      return defaultValue
    }
  }

  const defaultAccordionValue = accordionValue()

  return (
    <Accordion.Root type="single" className={styles.accordion} defaultValue={defaultAccordionValue}>
      {items.map((item) => {
        const isSelected = selectedItem === item.name
        return (
          <Accordion.Item
            key={item.name}
            value={item.name}
            className={styles.accordionContainer}
            style={{ '--accordion-bg-color': item.color } as CSSProperties}>
            <Accordion.Trigger className={styles.accordionTitle} style={{ '--accordion-bg-color': item.color } as CSSProperties}>
              <h4>{t(item.name)}</h4>
              <ArrowDownIcon className={styles.arrow} />
            </Accordion.Trigger>
            <Accordion.Content className={styles.accordionItemContent}>
              <ul className={styles.serviceContainer}>
                {item.services.map((offer: string) => (
                  <li key={offer} className={styles.service}>
                    <BulletIcon />
                    <Paragraph type={'small bold'}>{t(offer)}</Paragraph>
                  </li>
                ))}
              </ul>
              <div className={styles.extraInfo}>
                <h4>{t(item.price)}</h4>
                <Paragraph type={'small medium grey'}>{t(item.deadline)}</Paragraph>
              </div>
              <Button
                size={'m'}
                handleClick={() => {
                  setSelectedItem(item.name)
                  onSelectedItemChange(item.name)
                }}
                style={isSelected ? { backgroundColor: item.buttonColor } : {}}
                buttonText={isSelected ? 'Selected' : t('buttons.choose')} />
            </Accordion.Content>
          </Accordion.Item>
        )
      })}
    </Accordion.Root>
  )
}