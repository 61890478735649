import styles from './NavigationLinks.module.scss'
import { FC } from 'react'
import { navigation } from '../../../consts/navigation'
import { LinkComponent } from '../LinkComponent/LinkComponent'
import { useTranslation } from 'react-i18next'

export const NavigationLinks: FC = () => {
  const { t } = useTranslation()
  return (
    <nav className={styles.navigation}>
      {navigation.map((link) => (
        <LinkComponent key={link.linkText} path={link.path} linkText={t(link.linkText)} type={'small bold'} />
      ))}
    </nav>
  )
}