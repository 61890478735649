import { createContext, useContext, useState, ReactNode } from 'react'

interface SelectedItemContextType {
  selectedItem: string;
  setSelectedItem: (item: string) => void;
}

const SelectedItemContext = createContext<SelectedItemContextType>({
  selectedItem: '',
  setSelectedItem: () => {},
})

export const useSelectedItem = () => useContext(SelectedItemContext)

interface SelectedItemProviderProps {
  children: ReactNode;
}

export const SelectedItemProvider = ({ children }: SelectedItemProviderProps) => {
  const [selectedItem, setSelectedItem] = useState('')

  return (
    <SelectedItemContext.Provider value={{ selectedItem, setSelectedItem }}>
      {children}
    </SelectedItemContext.Provider>
  )
}
