import styles from './BlueCard.module.scss'
import { FC } from 'react'
import { Paragraph } from '../Paragraph/Paragraph'
import { Button } from '../Button/Button'
import Lama100 from '../../../assets/img/logos/lama-100.svg?react'
import { LogoFlip } from '../LogoFlip/LogoFlip'
import { useModal } from '../../../hooks/useModal'
import { useScrollToSection } from '../../../hooks/useScrollToSection'
import { useTranslation } from 'react-i18next'


export const BlueCard: FC = () => {
  const { openModal } = useModal()
  const scrollToSection = useScrollToSection()
  const { t } = useTranslation()
  return (
    <section className={styles.blueCard} id={'blueCard'}>
      <LogoFlip containerClassName={styles.logo} firstChild={<Lama100 />}
                secondChild={<Lama100 />} />
      <div className={styles.content}>
        <div className={styles.contentText}>
          <h2>Ready to stand out?</h2>
          <Paragraph type={'large regular'}>
            Let us guide you through the process of creating a distinctive brand that stands out and resonates with
            your target audience.
          </Paragraph>
        </div>
        <div className={styles.buttons}>
          <Button size={'m'} buttonText={t('buttons.getStarted')}
                  buttonStyles={styles.button} handleClick={() => openModal('')} />
          <Button size={'m'} buttonText={t('buttons.specialOffer')} buttonStyles={styles.button} fillType={'outlined'}
                  handleClick={() => scrollToSection('offer')} />
        </div>
      </div>
    </section>
  )
}


