import styles from './BookCall.module.scss'
import { FC } from 'react'
import { LogoFlip } from '../LogoFlip/LogoFlip'
import { Paragraph } from '../Paragraph/Paragraph'
import { Button } from '../Button/Button'
import { useModal } from '../../../hooks/useModal'
import Lama100 from '../../../assets/img/logos/lama-100.svg?react'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '../../../hooks/useBreakpoints'

export const BookCall: FC = () => {
  const { openModal } = useModal()
  const { t } = useTranslation()
  const { IS_MOBILE_OR_TABLET, isDesktopStandard } = useBreakpoints()
  return (
    <div className={styles.bookCall}>
      <LogoFlip containerClassName={styles.logoFlipContainer} firstChild={<Lama100 />} secondChild={<Lama100 />} />
      <div className={styles.bookCallText}>
        <div className={styles.bookCallContent}>
          <h4>{t('bookCall.title')}</h4>
          <Paragraph type={'medium regular'}>
            {t('bookCall.subtitle')}
          </Paragraph>
        </div>
        <Button
          size={IS_MOBILE_OR_TABLET || isDesktopStandard ? 'm' : 'l'}
          buttonText={t('buttons.bookCall')}
          handleClick={() => openModal('')} />
      </div>
    </div>
  )
}