import '../assets/fonts/fonts.css'
import React, { useEffect, useState } from 'react'
import { Header } from '../components/Header/Header'
import { MainContent } from '../components/MainContent/MainContent'
import { Footer } from '../components/Footer/Footer'
import { useModal } from '../hooks/useModal'
import { ContactUsModal } from '../components/ui/ContactUsModal/ContactUsModal'
import { useGeolocation } from '../hooks/useGeolocation'
import { HelmetComponent } from '../components/HelmetComponent/HelmetComponent'
import { CookieConsentComponent } from '../components/CookieConsent/CookieConsent'



const App: React.FC = () => {
  const [cookieConsentOpen, setCookieConsentOpen] = useState(false)
  const { isModalOpen } = useModal()
  useGeolocation()

  useEffect(() => {
    const hasSeenConsent = localStorage.getItem('hasSeenCookieConsent')
    setCookieConsentOpen(!hasSeenConsent)
  }, [])

  return (
    <>
      <HelmetComponent />
      <Header />
      <MainContent />
      <Footer />

      {
        isModalOpen && <ContactUsModal />
      }
      {cookieConsentOpen && <CookieConsentComponent cookieConsentOpen={cookieConsentOpen} setCookieConsentOpen={setCookieConsentOpen} />}
    </>
  )
}

export default App
