import styles from './LogoFlip.module.scss'
import React, { FC, MouseEventHandler, ReactElement, useState } from 'react'
import classNames from 'classnames'

interface Props {
  onClick?: MouseEventHandler
  firstChild: ReactElement
  secondChild: ReactElement
  containerClassName?: string
}

export const LogoFlip: FC<Props> = ({ firstChild, secondChild, onClick, containerClassName }) => {
  const [isFlipped, setIsFlipped] = useState(false)

  const handleMouseEnter = () => setIsFlipped(true)
  const handleMouseLeave = () => setIsFlipped(false)
  return (
    <div
      className={classNames(styles.logoFlipContainer, containerClassName)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`${styles.logoFlip} ${isFlipped ? styles.flipped : ''}`}>
        {React.cloneElement(firstChild, { onClick, className: styles.logo })}
        {React.cloneElement(secondChild, { onClick, className: `${styles.logo} ${styles.back}` })}
      </div>
    </div>
  )
}