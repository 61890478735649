export const projects = [
  {
    id: 1,
    name: 'projects.jupiter.title',
    description: 'projects.jupiter.subtitle',
    tags: [
      'services.branding',
      'services.presentation',
      'services.kit',
      'services.leaflet',
      'services.ux',
      'services.template'
    ],
    video: 'https://lama.global/promo/jupiter.mp4'
  },
  {
    id: 2,
    name: 'projects.INTI.title',
    description: 'projects.INTI.subtitle',
    tags: [
      'services.stage',
      'services.items',
      'services.presentation',
    ],
    video: 'https://lama.global/promo/inti.mp4'
  },
  {
    id: 3,
    name: 'projects.CHARME.title',
    description: 'projects.CHARME.subtitle',
    tags: [
      'services.research',
      'services.website',
      'services.presentation',
    ],
    image: 'https://lama.global/promo/CHARME.png'
  },
  {
    id: 4,
    name: 'projects.Rumped.title',
    description: 'projects.Rumped.subtitle',
    tags: [
      'services.presentation'
    ],
    video: 'https://lama.global/promo/rumped.mp4'
  },
  {
    id: 5,
    name: 'projects.Xiaomi.title',
    description: 'projects.Xiaomi.subtitle',
    tags: [
      'services.research',
      'services.website',
      'services.presentation'
    ],
    image: 'https://lama.global/promo/mishka.png'
  },
  {
    id: 6,
    name: 'projects.Aerogaz.title',
    description: 'projects.Aerogaz.subtitle',
    tags: [
      'services.research',
      'services.website',
      'services.presentation'
    ],
    video: 'https://lama.global/promo/aerogaz.mp4'
  }
]