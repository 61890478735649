import { FC } from 'react'
import { Paragraph } from '../ui/Paragraph/Paragraph'
import { Button } from '../ui/Button/Button'
import styles from './CookieConsent.module.scss'

interface Props {
  cookieConsentOpen: boolean
  setCookieConsentOpen: (cookieConsentOpen: boolean) => void
}

export const CookieConsentComponent: FC<Props> = ({setCookieConsentOpen, cookieConsentOpen,}) => {
  const handleAccept = () => {
    setCookieConsentOpen(false)
    localStorage.setItem('hasSeenCookieConsent', 'true')
  }

  if (!cookieConsentOpen) {
    return null
  }
  return (
    <div className={styles.cookies}>
      <Paragraph type={'medium regular'}>
        We use cookies to provide you with the best website experience
      </Paragraph>
      <Button size={'m'} buttonText={'Agree'} buttonStyles={styles.button} handleClick={handleAccept}/>
    </div>
  )
}