import { BehanceLogo, FacebookLogo, InstagramLogo, LinkedInLogo, TelegramLogo, WhatsAppLogo } from '../assets'
import styles from '../components/ui/Dropdown/Dropdown.module.scss'

export const socialLinks = [
  { icon: FacebookLogo, name: 'Facebook', className: styles.dropdownItem1, path: 'https://www.facebook.com/profile.php?id=100094235588568' },
  { icon: InstagramLogo, name: 'Instagram', className: styles.dropdownItem2, path: 'https://www.instagram.com/lama.global/' },
  { icon: LinkedInLogo, name: 'LinkedIn', className: styles.dropdownItem3, path: 'https://www.linkedin.com/in/lama-global-013515301/' },
  { icon: WhatsAppLogo, name: 'WhatsApp', className: styles.dropdownItem4, path: 'https://wa.me/message/MQ67BCTZZSODF1' },
  { icon: TelegramLogo, name: 'Telegram', className: styles.dropdownItem5, path: 'https://t.me/IanaTodorovskaya' },
  { icon: BehanceLogo, name: 'Behance', className: styles.dropdownItem6, path: 'https://www.behance.net/lamaagency' },
]