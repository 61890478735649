import styles from './MainContent.module.scss'
import { FC } from 'react'
import { Hero } from '../Hero/Hero'
import { Gallery } from '../Gallery/Gallery'
import { BlueCard } from '../ui/BlueCard/BlueCard'
import { SpecialOffer } from '../SpecialOffer/SpecialOffer'
import { Process } from '../Process/Process'
import { Services } from '../Services/Services'
import { Projects } from '../Projects/Projects'
import { CTA } from '../CTA/CTA'
import { Quote } from '../Quote/Quote'
import { useBreakpoints } from '../../hooks/useBreakpoints'
import { ProjectsTablet } from '../Projects/ProjectsTablet/ProjectsTablet'

export const MainContent: FC = () => {
  const {IS_MOBILE_OR_TABLET} = useBreakpoints()
  return (
    <main className={styles.main}>
      <Hero />
      <Gallery />
      {IS_MOBILE_OR_TABLET && <BlueCard />}
      <SpecialOffer />
      <Process/>
      <Services/>
      {IS_MOBILE_OR_TABLET ? <ProjectsTablet/> : <Projects/>}
      <Quote/>
      <CTA/>
    </main>
  )
}


