import { SocialLink } from '../../SocialLink/SocialLink'
import styles from '../Dropdown.module.scss'
import { Paragraph } from '../../Paragraph/Paragraph'
import { ElementType, FC } from 'react'
import classNames from 'classnames'

interface DropdownItemProps {
  icon: ElementType
  name: string
  className: string
  path: string
}

export const DropdownItem: FC<DropdownItemProps> = ({ icon, name, className, path }) => {

  return (
      <a href={path} target="_blank" rel="noopener noreferrer" className={classNames(styles.dropdownLink, className)} style={{ textDecoration: 'none', color: 'inherit' }}>
        <SocialLink icon={icon} className={styles.icon} />
        <div className={styles.hidden}>
          <Paragraph type={'caption-m regular'}>{name}</Paragraph>
        </div>
      </a>
  )
}