import styles from './Offer.module.scss'
import { FC } from 'react'
import { Paragraph } from '../Paragraph/Paragraph'
import { Button } from '../Button/Button'
import { OfferProps } from '../../../consts/offers'
import BulletIcon from '../../../assets/bullet.svg?react'
import { useModal } from '../../../hooks/useModal'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '../../../hooks/useBreakpoints'

interface Props {
  offer: OfferProps
}

export const Offer: FC<Props> = ({ offer }) => {
  const { name, color, description, deadline, price, services } = offer
  const { openModal } = useModal()
  const { t } = useTranslation()
  const {IS_MOBILE_OR_TABLET} = useBreakpoints()

  return (
    <li key={name} className={styles.offerPackage}>
      <div className={styles.offerPackageInfo} style={{ backgroundColor: color }}>
        <div className={styles.offerPackageText}>
          <div className={styles.offerPackageInfoTitle}>
            <h4>{t(name)}</h4>
            <Paragraph type={'medium regular'}>{t(description)}</Paragraph>
          </div>
          <div className={styles.offerPackageInfoPrice}>
            <h3>{t(price)}</h3>
            <Paragraph type={'medium regular grey'}>{t(deadline)}</Paragraph>
          </div>
        </div>
        <Button size={IS_MOBILE_OR_TABLET ? 'm' : 'l'}  buttonText={t('buttons.choose')} handleClick={() => openModal(name)} buttonStyles={styles.button} />
      </div>
      <div className={styles.offerContent}>
        <h4>{t('offer.included')}</h4>
        <ul className={styles.offerItems}>
          {services.map((offer: string) => (
            <li key={offer} className={styles.offer}>
              <BulletIcon />
              <Paragraph type={'medium regular'}>{t(offer)}</Paragraph>
            </li>
          ))}
          <Button size={'m'}  buttonText={t('buttons.choose')} handleClick={() => openModal(name)} buttonStyles={styles.buttonVisible} />
        </ul>
      </div>
    </li>
  )
}