import styles from './Header.module.scss'
import { FC, useState } from 'react'
import { LinkComponent } from '../ui/LinkComponent/LinkComponent'
import { Button } from '../ui/Button/Button'
import Logo from '../../assets/img/logos/logo.svg?react'
import { useModal } from '../../hooks/useModal'
import classNames from 'classnames'
import { useScrollDirection } from '../../hooks/useScrollDirection'
import {useScrollToTop} from '../../hooks/useScrollToTop'
import LamaLogo from '../../assets/img/logos/logo-lama.svg?react'
import { Dropdown } from '../ui/Dropdown/Dropdown'
import { NavigationLinks } from '../ui/NavigationLinks/NavigationLinks'
import { LogoFlip } from '../ui/LogoFlip/LogoFlip'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '../../hooks/useBreakpoints'

export const Header: FC = () => {
  const {openModal} = useModal()
  const scrollDirection = useScrollDirection()
  const isSticky = scrollDirection === 'up' && window.scrollY > 0
  const [scrollToTop, setScrollToTop] = useState(false)
  useScrollToTop(scrollToTop)
  const { t } = useTranslation()

  const handleScrollToTop = () => {
    setScrollToTop(true)
    setTimeout(() => setScrollToTop(false), 100)
  }

  const {IS_MOBILE_OR_TABLET, IS_MOBILE} = useBreakpoints()


  return (
    <header className={classNames(styles.header, isSticky && styles.sticky)}>
      <div className={styles.headerContainer}>
        {!IS_MOBILE_OR_TABLET && <NavigationLinks/>}
        <LogoFlip onClick={handleScrollToTop} firstChild={<Logo/>} secondChild={<LamaLogo/>}/>
        <div className={styles.headerNavigation}>
          {!IS_MOBILE_OR_TABLET && <Dropdown />}
          {!IS_MOBILE_OR_TABLET && <LinkComponent path={'offer'} linkText={t('navigation.links.offer')} type={'small bold'} />}
          <Button
            buttonText={t('buttons.contact')}
            buttonStyles={styles.button}
            handleClick={() => openModal('')}
            size={IS_MOBILE ? 's' : 'm'} />
        </div>
      </div>
    </header>
  )
}


