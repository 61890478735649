import styles from './Process.module.scss'
import { FC, useState } from 'react'
import { SectionWithSubtitle } from '../ui/SectionWithSubtitle/SectionWithSubtitle'
import { Table } from '../ui/Table/Table'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

export const Process: FC = () => {
  const [isStandard, setIsStandard] = useState<boolean>(false)
  const { t } = useTranslation()

  return (
    <SectionWithSubtitle title={t('process.title')} subtitle={t('process.subtitle')} id={'process'} className={styles.noPadding}>
      <div>
        <div className={styles.buttons}>
          <button className={classNames(styles.button, isStandard && styles.standardActive)}
                  onClick={() => setIsStandard(true)}>
            <h4>Standard</h4>
          </button>
          <button className={classNames(styles.button, !isStandard && styles.proActive)}
                  onClick={() => setIsStandard(false)}>
            <h4>Pro</h4>
          </button>
        </div>
        <div className={styles.scroll}>
          <Table isStandard={isStandard} />
        </div>
      </div>
    </SectionWithSubtitle>
  )
}


