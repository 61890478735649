import { useMemo } from 'react'

export function useTextStyles(type: string) {
  return useMemo(() => {
    let fontSizeType = ''
    let fontWeightType = ''
    let textColorType = ''

    const types = type.split(' ')

    if (types[0] === 'x-large') {
      fontSizeType = 'body-xl'
    } else if (types[0] === 'large') {
      fontSizeType = 'body-l'
    } else if (types[0] === 'medium') {
      fontSizeType = 'body-m'
    } else if (types[0] === 'small') {
      fontSizeType = 'body-s'
    } else if (types[0] === 'caption-xl') {
      fontSizeType = 'caption-xl'
    } else if (types[0] === 'caption-l') {
      fontSizeType = 'caption-l'
    } else if (types[0] === 'caption-m') {
      fontSizeType = 'caption-m'
    } else if (types[0] === 'caption-s') {
      fontSizeType = 'caption-s'
    }

    if (types.length > 1) {
      if (types[1] === 'regular') {
        fontWeightType = 'regular'
      } else if (types[1] === 'bold') {
        fontWeightType = 'bold'
      }
    }

    if (types.includes('grey')) {
      textColorType = 'body-grey'
    }

    if (types.includes('red')) {
      textColorType = 'body-red'
    }

    if (types.includes('outlined')) {
      textColorType = 'outlined'
    }


    return { fontSizeType, fontWeightType, textColorType }
  }, [type])
}
