import { CSSProperties, FC, MouseEventHandler, useState } from 'react'
import { Paragraph } from '../Paragraph/Paragraph'
import classNames from 'classnames'
import styles from './Button.module.scss'

interface Props {
  buttonText: string
  buttonStyles?: string
  fillType?: string
  handleClick?: MouseEventHandler
  size: 'l' | 'm' | 's'
  type?: 'button' | 'submit'
  style?: CSSProperties;
}
export const Button: FC<Props> = ({buttonText, buttonStyles, fillType= 'filled', type='button', handleClick, size = 'm', style }) => {
  const [isPressed, setIsPressed] = useState(false)
  let buttonTypeStyles: string

  switch (fillType) {
    case 'filled':
      buttonTypeStyles = 'filled'
      break
    case 'outlined':
      buttonTypeStyles = 'outlined'
      break
    default:
      buttonTypeStyles = ''
  }

  let paragraphType = ''
  let buttonSize =''
  if (size === 'l') {
    paragraphType = 'caption-l'
    buttonSize = 'button-l'
  } else if (size === 'm') {
    paragraphType = 'caption-l'
    buttonSize = 'button-m'
  } else if (size === 's') {
    paragraphType = 'caption-m'
    buttonSize = 'button-s'
  }

  const combinedClassName = classNames(buttonStyles, buttonSize,buttonTypeStyles,paragraphType, styles.button, { [styles.pressed]: isPressed })

  const handleClickWithAnimation = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (handleClick) {
      handleClick(event)
    }
    setIsPressed(true)
    setTimeout(() => setIsPressed(false), 100)
  }

  return (
    <button
      type={type}
      className={combinedClassName}
      onClick={handleClickWithAnimation}
      style={style}>
      <Paragraph type={paragraphType}>
        {buttonText}
      </Paragraph>
    </button>
  )}