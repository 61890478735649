import styles from './SuccessContent.module.scss'
import { FC } from 'react'
import { Paragraph } from '../../Paragraph/Paragraph'
import { LogoFlip } from '../../LogoFlip/LogoFlip'
import { SocialLink } from '../../SocialLink/SocialLink'
import Lama100 from '../../../../assets/img/logos/lama-100.svg?react'
import { socialLinks } from '../../../../consts/socialLinks'

export const SuccessContent: FC = () => {
  return (
    <div className={styles.success}>
      <LogoFlip containerClassName={styles.logoFlipContainer} firstChild={<Lama100 />}
                secondChild={<Lama100 />} />
      <div className={styles.text}>
        <h3>Sent successfully</h3>
        <Paragraph type={'medium regular'}>
          We will contact you shortly and discuss the format of work
        </Paragraph>
        <div className={styles.socials}>
          {socialLinks.map(link => (
            <SocialLink key={link.name} icon={link.icon} path={link.path} className={styles.link} />
          ))}
        </div>
      </div>
    </div>
  )
}