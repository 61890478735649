import styles from './Tag.module.scss'
import { FC } from 'react'
import { Paragraph } from '../Paragraph/Paragraph'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '../../../hooks/useBreakpoints'

interface Props {
  tag: string
  isHero?: boolean
}

export const Tag: FC<Props> = ({tag, isHero = true}) => {
  const { t } = useTranslation()
  const {IS_MOBILE_OR_TABLET} = useBreakpoints()

  const caption = !IS_MOBILE_OR_TABLET ? 'caption-l' : 'caption-m'

  return (
    <Paragraph type={`${caption} regular`} className={classNames(styles.tag, isHero ? styles.light : styles.dark)}>
      {t(tag)}
    </Paragraph>
  )
}