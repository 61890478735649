import styles from './SocialLink.module.scss'
import { ElementType, FC } from 'react'
import classNames from 'classnames'

interface SocialLinkProps {
  path?: string
  className?: string
  icon: ElementType
}

export const SocialLink: FC<SocialLinkProps> = ({ path, className, icon: Icon }) => {
  return (
    <a href={path} target="_blank" rel="nofollow noreferrer" className={classNames(styles.social, className)}>
      <Icon />
    </a>
  )
}