import { useEffect } from 'react'

type TriggerType = boolean
export const useScrollToTop = (trigger: TriggerType) => {
  useEffect(() => {
    if (trigger) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [trigger])
}

