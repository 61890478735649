import styles from './CTA.module.scss'
import  { FC } from 'react'
import { Button } from '../ui/Button/Button'
import { useModal } from '../../hooks/useModal'
import { useTranslation } from 'react-i18next'
import BackgroundVideo from '../../assets/videos/cta-bg.mp4'
import { useBreakpoints } from '../../hooks/useBreakpoints'


export const CTA: FC = () => {
  const {openModal} = useModal()
  const {IS_MOBILE_OR_TABLET} = useBreakpoints()
  const { t } = useTranslation()
  return (
    <section className={styles.cta}>
      <div className={styles.ctaContent}>
        <video loop muted autoPlay playsInline className={styles.video}  onClick={(e) => e.preventDefault()}>
          <source src={BackgroundVideo} type="video/mp4" />
        </video>
        <h1 className={styles.ctaContentTitle}>{t('cta.title')}</h1>
        <Button size={IS_MOBILE_OR_TABLET ? 'm' : 'l'} buttonText={t('buttons.getStarted')} buttonStyles={styles.button} handleClick={() => openModal('')}/>
      </div>
    </section>
  )
}


