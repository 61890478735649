import styles from './Projects.module.scss'
import { FC, useEffect, useState } from 'react'
import { SectionWithSubtitle } from '../ui/SectionWithSubtitle/SectionWithSubtitle'
import { projects } from '../../consts/projects'
import { Paragraph } from '../ui/Paragraph/Paragraph'
import { Tag } from '../ui/Tag/Tag'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
// import ProgressBar from '../../assets/icons/progress.svg?react'
import { useBreakpoints } from '../../hooks/useBreakpoints'


export const Projects: FC = () => {
  const { t } = useTranslation()
  const [currentProject, setCurrentProject] = useState(projects[0])
  const intervalTime = 20000
  const [progressWidth, setProgressWidth] = useState(0)
  const {IS_MOBILE_OR_TABLET} = useBreakpoints()
  useEffect(() => {
    const startTime = Date.now()

    const intervalId = setInterval(() => {
      const currentIndex = projects.findIndex(p => p.id === currentProject.id)
      const nextIndex = (currentIndex + 1) % projects.length
      setCurrentProject(projects[nextIndex])
      setProgressWidth(0)
    }, intervalTime)

    const updateProgress = () => {
      const elapsedTime = Date.now() - startTime
      const progressPercentage = (elapsedTime / intervalTime) * 100
      setProgressWidth(progressPercentage)
    }

    const progressInterval = setInterval(updateProgress, 100)

    return () => {
      clearInterval(intervalId)
      clearInterval(progressInterval)
    }
  }, [currentProject])


  const handleNextProject = () => {
    const currentIndex = projects.findIndex(p => p.id === currentProject.id)
    const nextIndex = (currentIndex + 1) % projects.length
    setCurrentProject(projects[nextIndex])
  }

  const progressPixels = (progressWidth / 100) * 200

  return (
    <SectionWithSubtitle title={t('projects.title')} subtitle={t('projects.subtitle')} id={'portfolio'}>
      <ul className={styles.projectsList} onClick={handleNextProject}>
        <li key={currentProject.id} className={styles.project}>

          <div className={styles.projectContent}>
            <div className={styles.projectText}>
              <div className={styles.projectTitle}>
                <div className={styles.progress}>
                  <h4
                    className={styles.projectLength}>{currentProject.id}/{projects.length} {t('projects.latest')}
                  </h4>
                  {/*<ProgressBar className={styles.progressIcon}/>*/}
                   <div className={styles.progressBar} style={{ width: `${progressPixels}px` }}></div>
                </div>
                <div className={styles.hidden}>
                  <h3 className={styles.slideUp}>{t(currentProject.name)}</h3>
                </div>
              </div>
              <div className={styles.hidden}>
                <Paragraph type={'medium regular'} className={styles.slideUp}>
                  {t(currentProject.description)}
                </Paragraph>
              </div>
            </div>
            <div className={styles.hidden}>
              <ul className={classNames(styles.tagList, styles.slideUp)}>
                {currentProject.tags.map((tag: string) => (
                  <Tag tag={tag} key={tag} isHero={false} />
                ))}
              </ul>
            </div>
          </div>
          <div className={classNames(styles.hidden, IS_MOBILE_OR_TABLET && styles.videoMobile)}>
            {currentProject.video ?
              <video autoPlay muted loop className={classNames(styles.image, styles.slideUp)}>
                <source src={currentProject.video} type="video/mp4" />
              </video> : <img
                className={classNames(styles.image, styles.slideUp)}
                src={currentProject.image}
                alt="Project image." />
            }
          </div>
        </li>
      </ul>
    </SectionWithSubtitle>
  )
}


