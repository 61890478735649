export const servicesHero = [
  'services.logo',
  'services.uiDesign',
  'services.presentation',
  'services.branding',
  'services.animation',
  'services.more'
]

export const additionalServicesRu = [
  'Аналитика',
  'Программирование',
  'Интеграции',
  'Поддержка',
  'Сопровождение',
  'App store оптимизация',
  'Ведение социальных сетей'
]
