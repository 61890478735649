import { useState, useEffect } from 'react'
import i18n from '../utils/i18n'

export const useGeolocation = () => {
  const [location, setLocation] = useState<{
    latitude: number | null;
    longitude: number | null;
    country: string | null;
    error: string | null;
  }>({
    latitude: null,
    longitude: null,
    country: null,
    error: null,
  })

  useEffect(() => {
    if (!navigator.geolocation) {
      setLocation({
        latitude: null,
        longitude: null,
        country: null,
        error: 'Geolocation is not supported by your browser.',
      })
    } else {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords
          setLocation({
            latitude,
            longitude,
            country: null,
            error: null,
          })

          try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=1&addressdetails=1`)
            const data = await response.json()
            const country = data.address && data.address.country ? data.address.country : 'Unknown'
            setLocation({
              latitude,
              longitude,
              country,
              error: null,
            })

            if (country.toLowerCase() === 'russia') {
              await i18n.changeLanguage('ru')
            }
          } catch (error) {
            console.error('Error fetching country:', error)
            setLocation({
              latitude: null,
              longitude: null,
              country: null,
              error: 'Failed to fetch country.',
            })
            await i18n.changeLanguage('ru')
          }
        },
        (error) => {
          setLocation({
            latitude: null,
            longitude: null,
            country: null,
            error: error.message,
          })
          i18n.changeLanguage('en')
        }
      )
    }
  }, [])

  return location
}

