import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import '../../../index.css'
import { useTextStyles } from '../../../hooks/useTextStyles'

interface  Props {
  type: string
  children: ReactNode
  className?: string
}

export const Paragraph: FC<Props> = ({ type, children, className }) => {
 const {fontWeightType, fontSizeType, textColorType} = useTextStyles(type)

  const combinedClassName = classNames(className, fontSizeType, fontWeightType, textColorType)

  return <p className={combinedClassName}>{children}</p>
}

