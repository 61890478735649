import styles from './SectionWithSubtitle.module.scss'
import { FC, ReactNode } from 'react'
import classNames from 'classnames'

interface Props {
  subtitle: string
  title: string
  children: ReactNode
  id?: string
  className?: string
}

export const SectionWithSubtitle: FC<Props> = ({subtitle, title, children, id, className}) => {
  return (
    <section className={classNames(styles.sectionWithSubtitle, className)} id={id}>
      <div className={styles.sectionWithSubtitle__titleText}>
        <h4 className={styles.sectionWithSubtitle__subtitle}>{subtitle}</h4>
        <h2>{title}</h2>
      </div>
        {children}
    </section>
  )
}


