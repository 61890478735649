import styles from './Gallery.module.scss'
import { FC, MutableRefObject, RefObject, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Paragraph } from '../ui/Paragraph/Paragraph'
import { Button } from '../ui/Button/Button'
import { VideoOnHover } from '../ui/VideoOnHover/VideoOnHover'
import Lama200 from '../../assets/img/logos/lama-200.svg?react'
import { LogoFlip } from '../ui/LogoFlip/LogoFlip'
import {
  JupiterVideo,
  PhoneVideo,
  WorkerVideo,
  CandleVideo,
} from '../../assets'
import { useModal } from '../../hooks/useModal'
import { useScrollToSection } from '../../hooks/useScrollToSection'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '../../hooks/useBreakpoints'

export const Gallery: FC = () => {
  const { openModal } = useModal()
  const scrollToSection = useScrollToSection()
  const { t } = useTranslation()
  const { IS_MOBILE_OR_TABLET } = useBreakpoints()
  const blueCardRef = useRef<HTMLElement | null>(null)
  const [isExpanded, setIsExpanded] = useState(false)
  const useDynamicSizeAdjustment = (ref: MutableRefObject<HTMLElement | null>, threshold = 250) => {
    const [dynamicStyle, setDynamicStyle] = useState({})

    useEffect(() => {
      const adjustSize = () => {
        const element = ref.current
        if (element) {
          const rect = element.getBoundingClientRect()

          const distanceFromTop = rect.top
          if (distanceFromTop <= threshold) {
            const newSize = {
              width: '110vw',
              height: '105vh',
              borderRadius: 'none',
            }
            setDynamicStyle(newSize)
            setIsExpanded(true)
          } else {
            setDynamicStyle({})
            setIsExpanded(false)
          }
        }
      }

      window.addEventListener('scroll', adjustSize)
      return () => window.removeEventListener('scroll', adjustSize)
    }, [ref, threshold])

    return dynamicStyle
  }

  const dynamicStyle = useDynamicSizeAdjustment(blueCardRef, 250)

  return (
    <section className={classNames(styles.gallery)}>
      <VideoOnHover videoSrc={PhoneVideo}
                    containerStyles={classNames(styles.cardXL, isExpanded && styles.cardShifted)} />
      <div className={styles.galleryContainer}>
        <VideoOnHover videoSrc={WorkerVideo}
                      containerStyles={classNames(styles.cardS, isExpanded && !IS_MOBILE_OR_TABLET && styles.cardShifted)} />
        {!IS_MOBILE_OR_TABLET && <div
          className={classNames(styles.card, styles.cardS, styles.presentationCard, isExpanded && !IS_MOBILE_OR_TABLET && styles.cardShifted)}>
          <h4>{t('gallery.experience')}</h4>
          <a className={classNames(styles.link, 'linkHover')}>
            <Paragraph type={'small bold'}>
              {t('services.presentation')}
            </Paragraph>
          </a>
        </div>}
      </div>
      <VideoOnHover videoSrc={JupiterVideo}
                    containerStyles={classNames(styles.cardM, isExpanded && styles.cardShifted)} />
      {!IS_MOBILE_OR_TABLET && <div ref={blueCardRef as RefObject<HTMLDivElement>} style={dynamicStyle}
                                    className={classNames(styles.card, styles.blueCard, isExpanded && styles.blueCardShifted)}>
        <LogoFlip containerClassName={isExpanded ? styles.lama : styles.logoFlipContainer} firstChild={<Lama200 />}
                  secondChild={<Lama200 />} />

        <div className={classNames(styles.galleryContent, isExpanded && styles.galleryContentExpanded)}>
          <div className={styles.galleryContentText}>
            {isExpanded ?
              <h2>{t('gallery.title')}</h2> :
              <h3>{t('gallery.title')}</h3>
            }

            {isExpanded ?
              <Paragraph type={'large regular'}>
                {t('gallery.subtitle')}
              </Paragraph> :
              <Paragraph type={'medium regular'}>
                {t('gallery.subtitle')}
              </Paragraph>
            }
          </div>
          <div className={styles.buttons}>
            <Button size={IS_MOBILE_OR_TABLET ? 'm' : 'l'} buttonText={t('buttons.getStarted')}
                    buttonStyles={styles.button} handleClick={() => openModal('')} />
            <Button size={IS_MOBILE_OR_TABLET ? 'm' : 'l'} buttonText={t('buttons.specialOffer')} buttonStyles={styles.button} fillType={'outlined'}
                    handleClick={() => scrollToSection('offer')} />
          </div>
        </div>
      </div>}
      <VideoOnHover videoSrc={CandleVideo}
                    containerStyles={classNames(styles.cardL, isExpanded && !IS_MOBILE_OR_TABLET && styles.transform2)} />
    </section>
  )
}


