import styles from './Services.module.scss'
import { FC } from 'react'
import { services } from '../../consts/services'
import { Paragraph } from '../ui/Paragraph/Paragraph'
import { useTranslation } from 'react-i18next'


export const Services: FC = () => {
  const { t } = useTranslation()
  return (
    <section className={styles.services} id={'services'}>
        <div className={styles.servicesContainerTitle}>
          <h2>{t('servicesSection.title')}</h2>
          <Paragraph type={'medium regular'}>
            {t('servicesSection.subtitle')}
          </Paragraph>
        </div>

      <ul className={styles.serviceList}>
        {services.map((service) => (
          <li key={service.number} className={styles.serviceListItem}>
            <div className={styles.serviceListItemName}>
              <h4 className={styles.number}>{service.number}</h4>
              <h4>{t(service.name)}</h4>
            </div>

            <ul>
              {service.work.map((w) => (
                <li key={w}>
                  <Paragraph type={'small regular'}>
                    {t(w)}
                  </Paragraph>
              </li>
            ))}
            </ul>
          </li>
        ))}
      </ul>
    </section>
  )
}


