import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Dropdown.module.scss'
import { Paragraph } from '../Paragraph/Paragraph'
import classNames from 'classnames'
import Arrow from '../../../assets/icons/arrowDown.svg?react'
import { DropdownItem } from './DropdownItem/DropdownItem'
import { socialLinks } from '../../../consts/socialLinks'


export const Dropdown: FC = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div className={styles.dropdown}>
      <div className={styles.trigger} onClick={toggleDropdown}>
        <Paragraph type={'small bold'} className={'linkHover'}>
          {t('navigation.links.social')}
        </Paragraph>
        <Arrow className={classNames(styles.arrow, isOpen && styles.rotated)} />
      </div>

      {isOpen && (
        <div className={classNames(styles.dropdownMenu, styles.dropdownMenuAnimated, styles.dropdownMenu1)}>
          {socialLinks.map((link, index) => (
            <DropdownItem key={index} path={link.path} icon={link.icon} name={link.name} className={link.className} />
          ))}
        </div>
      )}
    </div>
  )
}
